/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    ol: "ol",
    li: "li"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "Your current order Term will automatically renew for an equal order Term, except if you or we end the Services by sending a written notice of end of Services at least:"), "\n", React.createElement(_components.ol, null, "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "⏳ fifteen (15) days prior to the end of the current order Term, in which case this Agreement will terminate at the end of the current order Term, or"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "⏳sixty (60) days prior to the end of the current order Term, in which case this Agreement will terminate at the end of the current order Term."), "\n"), "\n"));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
